<template>
  <!-- 主报表开发 - 报表数据列 -->
  <div class="customModule">
    <custom-module-header title="报表数据列">
      <template v-slot:buttonBox>
<!--        <el-button type="primary" size="mini" @click="sqlParsing" >-->
<!--          SQL解析-->
<!--        </el-button>-->
        <el-button type="primary" size="mini" @click="addItem" >
          新增
        </el-button>
      </template>
    </custom-module-header>
    <div class="customModuleContent pTop15">
      <el-table
        :data="detailList"
        class="table-reset"
        border
        :max-height="reportTableHeight"
        style="width: 100%;"
        :header-cell-style="{ background: '#f0f2f5' }"
      >
        <el-table-column label="序号" min-width="100" type="index" align="center" fixed="left"></el-table-column>
        <el-table-column prop="name" label="字段" min-width="200px" fixed="left">
          <template slot="header" slot-scope="scope">
            <span><span style="color: red; margin-right: 5px;">*</span>字段</span>
          </template>
          <template scope="scope">
            <el-input v-model="scope.row.name" :disabled="types == 'look'"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="text" label="字段名称" min-width="200px" fixed="left">
          <template slot="header" slot-scope="scope">
            <span><span style="color: red; margin-right: 5px;">*</span>字段名称</span>
          </template>
          <template scope="scope">
            <el-input :disabled="types == 'look'" v-model="scope.row.text"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="类型" min-width="120px">
          <template slot="header" slot-scope="scope">
            <span><span style="color: red; margin-right: 5px;">*</span>类型</span>
          </template>
          <template scope="scope">
            <el-select :disabled="types == 'look'" style="width: 90%" clearable v-model="scope.row.type">
              <el-option
                v-for="(item, index) in typeOptions"
                :value="item.code"
                :label="item.attribute"
                :key="index"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="dataType" label="数据类型" min-width="150px">
          <template slot="header" slot-scope="scope">
            <span><span style="color: red; margin-right: 5px;">*</span>数据类型</span>
          </template>
          <template scope="scope">
            <el-select :disabled="types == 'look'" style="width: 90%" v-model="scope.row.dataType">
              <el-option
                v-for="(item, index) in dataTypeOptions"
                :value="item.code"
                :label="item.label"
                :key="index"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="placeFlag" label="显示位置" min-width="120">
          <template slot="header" slot-scope="scope">
            <span><span style="color: red; margin-right: 5px;">*</span>显示位置</span>
          </template>
          <template scope="scope">
            <el-select
              :disabled="types == 'look'"
              style="width: 90%"
              v-model="scope.row.placeFlag">
              <el-option :value="1" label="居左"></el-option>
              <el-option :value="2" label="居中"></el-option>
              <el-option :value="3" label="居右"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="detailUrl" label="明细URL" min-width="140">
          <template scope="scope">
            <el-input :disabled="types == 'look'" v-model="scope.row.detailUrl"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="isQueryType" label="是否查询条件" min-width="120px">
          <template scope="scope">
            <el-select :disabled="types == 'look'" style="width: 90%" v-model="scope.row.isQueryType">
              <el-option :value="0" label="否"></el-option>
              <el-option :value="1" label="是"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="queryType" label="查询类型" min-width="150px">
          <template scope="scope">
            <el-select :disabled="types == 'look'" style="width: 90%" v-model="scope.row.queryType">
              <el-option :value="1" label="文本"></el-option>
              <el-option :value="2" label="下拉框"></el-option>
              <el-option :value="3" label="下拉框(多选)"></el-option>
              <el-option :value="4" label="日期：年-月-日"></el-option>
              <el-option :value="5" label="日期：年-月"></el-option>
              <el-option :value="6" label="日期：年"></el-option>
              <el-option :value="7" label="日期：月"></el-option>
              <el-option :value="8" label="日期：日期范围"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="queryTypeData" label="查询数据源" min-width="140">
          <template slot="header" slot-scope="scope">
            <span>
              <span >查询数据源</span>
              <el-popover
                placement="top"
                width="150"
                trigger="hover">
                <p>查询类型选择下拉框时 查询数据源请按照如下示例填写（其中的逗号“,”为英文符号）：财务一组,财务二组,财务三组</p>
                <i slot="reference" class="el-icon-question"
                   style="font-size: 17px; color: #1d80fe; cursor: pointer; margin-left: 2px;"></i>
              </el-popover>
            </span>
          </template>
          <template scope="scope">
            <el-input
              style="width: 90%"
              :disabled="types == 'look'"
              v-model="scope.row.queryTypeData"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="isFixed" label="是否固定列" min-width="120">
          <template slot="header" slot-scope="scope">
            <span>
              <span >是否固定列</span>
              <el-popover
                placement="top"
                width="150"
                trigger="hover">
                <p>请选择需要固定的那一个列即可，其他的列不需要选择</p>
                <i slot="reference" class="el-icon-question"
                   style="font-size: 17px; color: #1d80fe; cursor: pointer; margin-left: 2px;"></i>
              </el-popover>
            </span>
          </template>
          <template scope="scope">
            <el-select :disabled="types == 'look'" style="width: 90%" v-model="scope.row.isFixed">
              <el-option :value="0" label="否"></el-option>
              <el-option :value="1" label="是"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="isSubQueryType" label="是否子表条件" min-width="120">
          <template scope="scope">
            <el-select :disabled="types == 'look'" style="width: 90%" v-model="scope.row.isSubQueryType">
              <el-option :value="0" label="否"></el-option>
              <el-option :value="1" label="是"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="comment" label="备注" min-width="120">
          <template scope="scope">
            <el-input :disabled="types == 'look'" v-model="scope.row.comment"></el-input>
          </template>
        </el-table-column>
        <el-table-column v-if="types != 'look'" label="操作" align="center" width="80">
          <template slot-scope="scope">
            <el-button type="danger" style="padding: 5px" @click="deleteItem(scope.$index)" icon="el-icon-delete" size="mini">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "easyReportDataDrawer",
  props: {
    // 报表数据列
    detailList: {
      type: Array,
      default: () => {
        return []
      }
    },
    types: {
      type: String,
      default: "look"
    },
    typeOptions: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      dataTypeOptions: [
        {
          label: 'VARCHAR',
          code: 0
        },
        {
          label: 'DECIMAL',
          code: 1
        },
        {
          label: 'DATETIME',
          code: 2
        },
        {
          label: 'INT',
          code: 3
        },
        {
          label: 'BIGINT',
          code: 4
        },
        {
          label: 'TINYINT',
          code: 5
        },
      ], //数据类型
      reportTableHeight: 500,
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.reportTableHeight = (document.body.clientHeight - 420)
    })
  },
  methods: {
    // sql解析
    sqlParsing() {
      this.$emit("sqlParsing")
    },
    // 新增行
    addItem() {
      this.$emit("addItem")
    },
    // 删除行
    deleteItem(index) {
      this.$emit("deleteItem", index)
    },
  },
}
</script>

<style lang="scss" scoped>
  .customModule {
    width: 100%;
  }
  .create-main .header-top span {
    width: 100px;
  }
</style>
