<template>
  <div class="content-wrap">
    <el-dialog
      title="报表开发"
      :before-close="dialogHide"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      :width="mainDialogWidth"
      :fullscreen="false"
      :modal="false"
      @open="dialogOpen"
    >
      <div class="create-header">
        <div class="header-top">
          <span>{{ title }}</span>
          <div></div>
        </div>
        <div class="header-bottom">
          <el-form ref="formRef" :model="formInfo" :rules="formRules" label-width="140px">
            <el-row>
              <el-col :span="8">
              </el-col>
              <el-col :span="8">
                <el-form-item prop="uid" label="UID编号">
                  <el-input v-model="formInfo.uid" disabled></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="报表分类"
                  class="select-width"
                  prop="categoryId"
                  :required="true"
                >
                  <el-select
                    :disabled="types == 'look'"
                    v-model="formInfo.categoryId"
                  >
                    <el-option
                      v-for="(item, itemIndex) in categoryIdOptions"
                      :key="itemIndex"
                      :label="item.attribute"
                      :value="item.code"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item class="select-width" label="报表名称" prop="name">
                  <el-input v-model="formInfo.name" :disabled="types == 'look'"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="16">
                <el-form-item label="说明备注" prop="comment">
                  <el-input type="textarea" v-model="formInfo.comment"/>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="20">
                <el-form-item
                  class="sqlTextarea"
                  label="报表SQL语句"
                  prop="sqlText"
                  :required="true"
                >
                  <div>
                    <div style="width: 100%; margin-bottom: 10px">
                      <el-button size="mini" type="primary" @click="sqlParsing">SQL解析</el-button>
                      <span style="width: 110px; float: right; margin-right: 10px">
                        <span style="color: red">注：</span>子查询#{id} 为必录条件
                      </span>
                    </div>
                    <sqlEditor
                      ref="sqlEditor"
                      :value="formInfo.sqlText"
                      :tables="sqlTable"
                      :read-only="types == 'look'"
                      @changeTextarea="changeTextarea($event)"
                    ></sqlEditor>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          </div>
      </div>

      <div class="create-main">
        <div class="header-top">
          <span>报表数据列</span>
          <div class="top-right">
            <el-button class="el-button-ext" v-loading="saveBtnLoading" @click="saveBtn" type="primary">保存</el-button>
          </div>
        </div>
        <div class="license-body">
          <!-- 报表数据列 -->
          <easy-report-data-drawer
              ref="easyReportDataDrawer"
              :detailList="formInfo.detailList"
              :types="types"
              :type-options="typeOptions"
              @sqlParsing="sqlParsing"
              @addItem="addItem"
              @deleteItem="deleteItem"
          ></easy-report-data-drawer>

          <el-drawer :visible.sync="reportDataDrawer" :before-close="handleDrawerClose"
                    direction="rtl" size="75%" :show-close="false"
                    class="customDrawerWrap">
            <template slot="title">
              <div class="customDrawerTitle">
                <span class="title">报表数据列</span>
              </div>
            </template>
          </el-drawer>
        </div>
      </div>

      <div class="edit-select">
        <div class="edit-select-foot">
          <el-button class="el-button-ext" @click="dialogHide">取消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {tableStyle} from '@/util/mixins';
import {execSqlText, getOrderById, saveReport} from "@/service/easyReport";
import sqlEditor from "@/views/easyReport/sqlEditor";
import EasyReportDataDrawer from "@/views/easyReport/easyReportDataDrawer.vue";
import { nanoid } from "nanoid";

export default {
  name: 'create',
  components: { sqlEditor, EasyReportDataDrawer },
  mixins: [tableStyle],
  props: {
    //弹窗开关
    dialogFormVisible: {
      type: Boolean,
    },
    // 编辑状态
    types: {
      type: String,
      default: "look",
    },
    // 弹窗大小
    mainDialogWidth: {
      type: String,
      default: "",
    },
    // 行数据
    rowData: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  data () {
    return {
      tableData: [],
      sqlTable: {},
      title: '报表开发',
      isEdit: false,
      imageUrl: '',
      formInfo: {
        id: "", // id
        uid: "", // uid
        isMultipleHead: 0, // 是否多级表头
        categoryId: "1", //报表类型
        comment: "", // 说明备注
        name: "", //报表名称
        sqlText: "", //报表SQL语句
        metaColumns: "", //报表列
        enterpriseId: "", // 公司id
        enterpriseName: "", // 公司 名称
        detailList: [], //报表明细
      },
      categoryIdOptions: [], // 报表分类
      typeOptions: [], // 类型下拉列表
      rules: {
        name: [
          {required: true, message: "请选择报表名称", trigger: "change"},
        ],
      },
      menuList: [],
      reportDataDrawer: false, // 报表数据列
      businessLicense: '',
      saveBtnLoading: false
    }
  },
  computed: {
    formRules: function () {
      return this.rules
    }
  },
  methods: {
    dialogOpen() {
      this.$nextTick(() => {
        this.$refs.formRef.resetFields()
      })
      this.initMenuList()
      if(this.types == 'add') {
        // 新增
        this.getOptions()
      }else if(this.types == 'edit') {
        // 编辑
        this.loadData()
        this.getOptions()
      }else if(this.types == 'look') {
        // 查看
        this.loadData()
      }
    },
    loadData() {
        this.getOrderById();
    },
    getOrderById() {
      let params = {
        id: this.rowData.id
      }
      return getOrderById(params).then(res => {
        if(res.success) {
          Object.assign(this.formInfo, res.data)
          this.$set(this.formInfo, 'detailList', JSON.parse(res.data.metaColumns))
          this.$nextTick(() => {
            this.$refs.sqlEditor.setVal()
          })
        }
      })
    },
    initMenuList() {
      let arr = [
        {key: 'reportDataDrawer', title: '报表数据列'},
      ]
      this.menuList = arr
    },
    // 浮动菜单点击事件
    floatMenuClick(key) {
      this.showDrawer(key, () => {
        this.handleDrawerClose()
      })
    },
    // 关闭所有抽屉
    handleDrawerClose(shutdown) {
      this.reportDataDrawer = false
      this.reportHeadersDrawer = false
      return true
    },
    //实时记录sql输入变化
    changeTextarea(val) {
      this.$set(this.formInfo, 'sqlText', val)
    },
    // 新增报表数据列
    addItem() {
      let item = {
        id: "",
        name: "",
        text: "",
        type: "",
        dataType: "",
        width: "",
        placeFlag: 1, // 显示位置 1居左   2居中   3居右
        sortType: "0",
        decimals: 0,
        percent: null,
        optional: null,
        isDeleted: 0,
        displayInMail: null,
        isQueryType: 0, // 是否查询条件  0否  1是
        queryType: '', // 查询类型  1文本  2 下拉框  3 下拉框多选  4 日期：年月日  5 日期：年月  6 日期：年  7 日期：月  8 日期：日期范围
        queryTypeData: '', // 查询数据源
        isFixed: 0, // 是否固定列
        isSubQueryType: 0, // 是否子表条件
        uid: this.formInfo.uid || '',
        nanoid: nanoid(),
        detailUrl: '',
      }
      this.formInfo.detailList.push(item)
    },
    // 删除数据列
    deleteItem(index) {
      this.formInfo.detailList.splice(index, 1)
    },
    // sql解析
    sqlParsing() {
      let flag = true;
      if (this.isEmpty(this.formInfo.sqlText)) {
        flag = false;
        this.$message.warning("SQL语句为必填！");
        return;
      }
      if (flag) {
        let loadingMsgText = "执行中,请稍后。。。";
        const loading = this.$loading({
          lock: true,
          text: loadingMsgText,
          spinner: "el-icon-loading",
        });
        let data = {
          id: this.formInfo.id,
          sqlText: this.formInfo.sqlText
        }
        execSqlText(data)
          .then((res) => {
            if (res.success) {
              loading.close();
              this.$message({
                type: "success",
                message: res.message,
              });
              this.$set(this.formInfo, "detailList", res.data);
            }else{
              loading.close();
            }
          })
          .catch(() => {
            loading.close();
          });
      }
    },
    //保存
    async saveBtn() {
      this.formInfo.metaColumns = JSON.stringify(this.formInfo.detailList);
      let sortTypeCount = 0;
      if (this.checkParams()) {
        this.$refs["formRef"].validate((valid) => {
          if (valid) {
            let flag = true;
            if (this.formInfo.detailList.length == 0) {
              flag = false;
              this.$message.warning("明细不能为空！");
              return;
            }
            this.formInfo.detailList.map((i) => {
              if (this.isEmpty(i.name)) {
                flag = false;
                this.$message.warning("报表数据列：“字段” 为必填！");
                return;
              }
              if (this.isEmpty(i.text)) {
                flag = false;
                this.$message.warning("报表数据列：“字段名称” 为必填！");
                return;
              }
              if (this.isEmpty(i.type)) {
                flag = false;
                this.$message.warning("报表数据列：“类型” 为必填！");
                return;
              }
              if (this.isEmpty(i.dataType)) {
                flag = false;
                this.$message.warning("报表数据列：“数据类型” 为必填！");
                return;
              }
              if (!this.isEmpty(i.sortType) && 0 != i.sortType) sortTypeCount++;
            });
            if (sortTypeCount > 1) {
              flag = false;
              this.$message.warning(
                "报表数据列：“数据类型”只能选择一个排序条件 ！"
              );
              return;
            }
            if (flag) {
              let loadingMsgText = '保存中,请稍后。。。'
              const loading = this.$loading({
                lock: true,
                text: loadingMsgText,
                spinner: "el-icon-loading",
              });
              saveReport(this.formInfo)
                .then((res) => {
                  if (res.success) {
                    loading.close();
                    this.$message({
                      type: "success",
                      message: res.message,
                    });
                    this.dialogHide("onLoad");
                  }
                })
                .catch(() => {
                  loading.close();
                });
            }
          } else {
            return false;
          }
        });
      }
    },
    // 保存、提交 验证
    checkParams() {
      if (this.isEmpty(this.formInfo.name)) {
        this.$message.error("请填写报表名称");
        return false;
      }
      if (this.isEmpty(this.formInfo.sqlText)) {
        this.$message.error("请填写报表sql语句");
        return false;
      }
      if (this.isEmpty(this.formInfo.categoryId)) {
        this.$message.error("请填写报表分类");
        return false;
      }
      return true;
    },
    //关闭弹窗框
    dialogHide(val) {
      this.$emit("dialogBoolHide", val);
    },
    // 重置form
    resetForm() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    // 获取options配置
    getOptions() {
      this.categoryIdOptions =[
        {
          "code": "1",
          "attribute": "普通报表"
        },
        {
          "code": "2",
          "attribute": "折线图表"
        },
        // {
        //   "code": "3",
        //   "attribute": "柱状图表"
        // },
        // {
        //   "code": "4",
        //   "attribute": "饼状图表"
        // },
      ];

      this.typeOptions = [
        {
          "code": "1",
          "attribute": "布局列"
        },
        {
          "code": "2",
          "attribute": "维度列"
        },
        {
          "code": "3",
          "attribute": "统计列"
        },
        {
          "code": "4",
          "attribute": "计算列"
        }
      ];
    },
    isEmpty(val) {
      if (
        val === null ||
        typeof val === 'undefined' ||
        (typeof val === 'string' && val === '' && val !== 'undefined')
      ) {
        return true;
      }
      return false;
    },
  }
}
</script>
<style lang="scss" scoped>
  .create-header {
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 2px 8px 0px rgba(153, 153, 153, 0.1);

    .header-top {
      padding: 16px 24px;
      font-size: 16px;
      font-weight: 500;
      border-bottom: 1px solid #e9e9e9;
      color: #333;
      display: flex;

      div {
        width: 406px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #F5222D;
        line-height: 20px;
        margin-left: 16px;
      }

      span:before {
        content: "|";
        display: inline-block;
        font-size: 16px;
        margin-right: 10px;
        background-color: #333;
      }

    }

    .header-bottom {
      margin-bottom: 24px;

      .el-row {
        margin: 24px 0px;

        &:last-child {
          padding-bottom: 20px;
        }
      }

      label {
        width: 70px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        line-height: 20px;
      }

      span {
        width: 56px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        line-height: 20px;
      }

    }
  }

  .create-main {
    width: 100%;
    height: 580px;
    background-color: #fff;
    overflow-x: auto;
    margin-top: 440px;
    margin-bottom: 24px;

    .header-top {
      padding: 16px 24px;
      font-size: 16px;
      font-weight: 500;
      border-bottom: 1px solid #e9e9e9;
      color: #333;
      display: flex;

      div {
        width: 406px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #F5222D;
        line-height: 20px;
        margin-left: 16px;
      }

      span:before {
        content: "|";
        display: inline-block;
        font-size: 16px;
        margin-right: 10px;
        background-color: #333;
      }
      
      .top-right {
        width: 906px;
        height: 20px;
        text-align: right;
      }

    }

    .license-title {
      height: 40px;
      margin: 24px;
      background: rgba(235, 244, 255, 1);
      display: flex;
      align-items: left;
      padding-left: 24px;

      img {
        width: 16px;
        height: 16px;
        margin-right: 3px;
      }

      span {
        // width: 1082px;
        width: 882px;
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
      }
    }

    .license-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 10px;
      margin-right: 10px;

      .license-img {
        width: 180px;
        height: 240px;
        background: rgba(229, 229, 229, 1);
        margin-bottom: 48px;
        display: flex;
        justify-content: center;
        align-items: center;

        .license-show {
          width: 180px;
          height: 240px;
        }

        img {
          width: 58px;
          height: 58px;
        }
      }

      .el-button {
        width: 120px;
      }
    }
    
    .header-bottom {
      margin-bottom: 24px;

      .el-row {
        margin: 24px 0px;

        &:last-child {
          padding-bottom: 20px;
        }
      }

      label {
        width: 70px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        line-height: 20px;
      }

      span {
        width: 56px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        line-height: 20px;
      }

    }

    .create-main-row {
      width: 90%;
      display: flex;
      justify-content: flex-start;
      text-align: top;

      .el-form-item {
        width: 160px;
      }

      .el-select {
        width: 100%;
      }
      
        .el-input el-input--suffix {
          width: 100px;
        }
      .el-checkbox__label {
        width: 150px;
      }
    }

  }

  .header-top {
    padding: 16px 24px;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid #e9e9e9;
    color: #333;

    span:before {
      content: "|";
      display: inline-block;
      font-size: 16px;
      margin-right: 10px;
      background-color: #333;
    }
  }
  .edit-select {
    height: 60px;
  }
  
  .el-input-number {
    width: 200px;
    
    .el-input__inner {
      text-align: right;
    }

  }
</style>
<style>
  .el-form-item__error {
    top: 30px;
  }
</style>
