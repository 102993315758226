import { get, post, download, del, put } from './index';


/*
 * --------------------------主报表开发---------------------------
 */
// 1.1 查询报表开发详情
export const getOrderById = (params) =>{
  return get('/easyReport/getOrderById', params).then((res) => res);
};

// 1.2 查询报表开发分页列表
export const searchOrderPage = (data) => {
  return post('/easyReport/searchOrderPage', data).then((res) => res);
};

// 1.3 导出报表开发
export const exportEasyReport = (data) => {
  return download('/easyReport/exportEasyReport', data).then((res) => res);
};

// 1.4 保存报表开发
export const saveReport = (data) =>{
  return post('/easyReport/saveReport', data).then((res) => res);
}

// 1.5 执行SQL语句
export const execSqlText = (data) =>{
  return post('/easyReport/execSqlText', data).then((res) => res);
}

// 1.6 开票申请批量删除
export const deleteReport = (data) =>{
  return post('/easyReport/deleteReport', data).then((res) => res);
}

 

//////////////////////////////// 明细报表开发 ////////////////////////////////

// 1.1 查询报表开发详情
export const getDetailedById = (params) =>{
  return post('/easyReport/getDetailedById', params).then((res) => res);
}

// 1.2 查询报表开发分页列表
export const searchDetailedPage = (data) =>{
  return post('/easyReport/searchDetailedPage', data).then((res) => res);
}


// 1.3 导出报表开发
export const exportEasyReportDetailed = (data) => {
  return download('/easyReport/exportEasyReportDetailed', data).then((res) => res);
};

//明细报表展现分页列表
export const searchReportDetailedDisplayPage = (data) =>{
  return post('/easyReport/searchReportDetailedDisplayPage', data).then((res) => res);
}
 

//报表展现分页列表
export const searchReportDisplayPage = (data) =>{
  return post('/easyReport/searchReportDisplayPage', data).then((res) => res);
}
