<template>
  <div :style="{'--sqlHeight': sqlHeight }">
    <textarea ref="sqlEditor" v-model="value" class="codesql" ></textarea>
  </div>
</template>

<script>
import "codemirror/theme/ambiance.css";
import "codemirror/lib/codemirror.css";
import "codemirror/addon/hint/show-hint.css";
let CodeMirror = require("codemirror/lib/codemirror");
require("codemirror/addon/edit/matchbrackets.js");
require("codemirror/addon/selection/active-line");
require("codemirror/mode/sql/sql");
require("codemirror/addon/hint/show-hint.js");
require("codemirror/addon/hint/sql-hint");
require("codemirror/theme/abcdef.css")

export default {
  name: "sqlEditor",
  props: {
    // 接收父组件传值
    value: {
      type: String,
      default: ''
    },
    sqlStyle: {
      type: String,
      default: 'default'
    },
    readOnly: {
      type: [Boolean, String]
    },
    // 父组件将表结构传给编辑器，实现自动提示表名和字段名的功能
    tables: {
      type: Object,
      default: () => {
      }
    }
  },
  watch: {
    // 监听newVal值的变化，通过getValue方法获取到值并传递给父组件
    newVal(newV, oldV) {
      if (this.editor) {
        this.$emit('changeTextarea', this.editor.getValue());
      }
    },
  },
  computed: {
    newVal() {
      if (this.editor) {
        return this.editor.getValue();
      }
    }

  },
  data() {
    return {
      editor: null,
      sqlHeight: '500px',
    }
  },
  mounted() {
    let mime = 'text/x-mariadb'
    // let theme = 'ambiance'//设置主题，不设置的会使用默认主题
    let theme = 'abcdef'//设置主题，不设置的会使用默认主题
    this.editor = CodeMirror.fromTextArea(this.$refs.sqlEditor, {
      value: this.value,
      mode: { name: 'text/x-mysql' },
      indentWithTabs: true,
      smartIndent: true,
      lineNumbers: true,
      matchBrackets: true,
      cursorHeight: 1,
      lineWrapping: true,
      readOnly: this.readOnly,
      theme: theme,
      autofocus: true,
      extraKeys: { Ctrl: 'autocomplete' },
      hintOptions: {
        completeSingle: false,
        tables: this.tables
      }
    });
    //代码自动提示功能，记住使用cursorActivity事件不要使用change事件，这是一个坑，那样页面直接会卡死==编辑器触发
    this.editor.on('inputRead', () => {
      this.editor.showHint()
    })
    // 设置窗口高度
    // let height = (document.body.clientHeight - 440) + 'px'
    let height = (document.body.clientHeight - 460) + 'px'
    this.sqlHeight = height
  },
  methods: {
    setVal() {
      if (this.editor) {
        if (this.value === '') {
          this.editor.setValue('')
        } else {
          this.editor.setValue(this.value)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.CodeMirror {
  color: black;
  direction: ltr;
  line-height: 22px;
}
::v-deep .cm-s-abcdef.CodeMirror {
  height: var(--sqlHeight); // sql编辑器窗口高度
  border: 1px #00a680 solid;
}
.CodeMirror-hints {
  z-index: 9999 !important;
}
</style>
